import { ref, onBeforeMount } from '@vue/composition-api'
import api from '@/app/empresas/shared/services/api'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { updateAbility, getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import Vue from 'vue'

export default function useEmpresa(modoEdicao, validacao, ability) {
  const toast = useToast()
  const form = ref(null)
  const { router } = useRouter()

  onBeforeMount(() => {
    form.value = {
      cadastroFederalTipo: 'cnpj',
      cadastroFederal: '',
      razaoSocial: '',
      naturezaJuridica: '',
      nomeFantasia: '',
      inscricaoEstadual: '',
      cnaePrincipal: {},
      cnaeSecundario: {},
      contador: {},
      ativa: true,
      endereco: {
        cep: '',
        tipoLogradouro: '',
        logradouro: '',
        numero: '',
        email: '',
        emailAdicional: '',
        telefoneContato: {

        },
      },
      socios: [{ show: false }],
    }
  })

  const carregar = id => {
    api.getEmpresa(id)
      .then(payload => {
        form.value = payload.data
        if (!form.value.cnaePrincipal) {
          form.value.cnaePrincipal = {}
        }
      })
      .catch(err => {
        throw err
      })
  }

  const novo = () => {
    api.novaEmpresa()
      .then(payload => {
        if (payload.data.contador.id !== 0) {
          form.value.contador = payload.data.contador
        } else {
          Vue.swal({
            title: '<h4>Usuário contador não cadastrado!</h4>',
            html: '<div>Faça o cadastro de um usuário contador antes de continuar. <a style="color: #ff9f43; text-decoration: underline;" href="../usuario/visualizar?busca=">Clique aqui</a> para concluir seu cadastro</div>',
            icon: 'warning',
            iconColor: '$warning',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-outline-dark mr-50',
            },
            buttonsStyling: false,
          })
        }
      })
      .catch(err => {
        throw err
      })
  }

  const salvar = () => {
    validacao.value.validate()
      .then(success => {
        if (success) {
          if (form.value.existeCnpj) {
            toast.error({
              component: ToastificationContent,
              props: {
                title: 'Já existe outra empresa cadastrada com o mesmo número de CPF/CNPJ',
                icon: 'fa-solid fa-circle-exclamation',
                variant: 'danger',
              },
            })
          } else if (form.value.cnaesSecundarios.some(s => (s.descricao === undefined || s.descricao === '') && s.emEdicao === false)) {
            toast.error({
              component: ToastificationContent,
              props: {
                title: 'Preencha corretamente os CNAEs ou contate o suporte!',
                icon: 'fa-solid fa-circle-exclamation',
                variant: 'danger',
              },
            })
          } else if (modoEdicao && form.value.id > 0) {
            return new Promise((resolve, reject) => {
              api.alterarEmpresa(form.value.id, form.value)
                .then(() => {
                  resolve('Empresa atualizado com sucesso')
                  router.go(-1)
                })
                .catch(err => {
                  reject(err)
                  if (!err.response || (err.response && (err.response.status !== 500 || err.response.status !== 400))) {
                    router.go(-1)
                  }
                })
            })
          } else {
            return new Promise((resolve, reject) => {
              api.salvarEmpresa(form.value)
                .then(() => {
                  resolve('Empresa cadastrado com sucesso')
                  const userData = getUserData()
                  api.atualizarToken(userData.email)
                    .then(payload => {
                      useJwt.setUserData(payload.data.userData)
                      useJwt.setToken(payload.data.accessToken)
                      updateAbility(ability)
                      router.go(-1)
                    })
                    .catch(err => {
                      reject(err)
                      if (!err.response || (err.response && (err.response.status !== 500 || err.response.status !== 400))) {
                        router.go(-1)
                      }
                    })
                })
                .catch(err => {
                  reject(err)
                  if (!err.response || (err.response && (err.response.status !== 500 || err.response.status !== 400))) {
                    router.go(-1)
                  }
                })
            })
          }
          return null
        }
        this.$erro('Preencha os campos corretamente', 'danger')
        return null
      })
  }

  const cancelar = () => {
    router.go(-1)
  }

  return {
    salvar,
    carregar,
    novo,
    cancelar,
    form,
  }
}
