import axios from '@axios'

const PREFIX = '/empresas'

export default {

  atualizarToken(email) {
    return axios.post(`/acessos/atualizar-token/${email}`)
  },

  salvarEmpresa(empresa) {
    return axios.post(`${PREFIX}`, empresa)
  },

  verificarLimiteCadastro() {
    return axios.get(`${PREFIX}/verificar-limite`)
  },

  alterarEmpresa(id, empresa) {
    return axios.put(`${PREFIX}/${id}`, empresa)
  },

  removerEmpresa(ids) {
    if ((Array.isArray(ids) && ids.length > 1)) {
      return axios.delete(`${PREFIX}/lote?valores=[${ids}]`)
    }
    return axios.delete(`${PREFIX}/${ids}`)
  },

  getPaginado(page, pageSize, filtro) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtro)
  },

  getEmpresa(id) {
    return axios.get(`${PREFIX}/${id}`)
  },

  getEmpresaCadastroFederal(cadastro, empresa) {
    return axios.post(`${PREFIX}/buscar?cadastro=${cadastro}`, empresa)
  },

  getEmpresasResumidos(filtro, ativos) {
    return axios.post(`${PREFIX}/resumido?ativo=${ativos}`, filtro)
  },

  importarEmpresas(value) {
    const formData = new window.FormData()
    formData.append('arquivoEmpresas', value.arquivoEmpresas.file, value.arquivoEmpresas.fileName)
    if (value.certificado && value.certificado.id) {
      return axios.post(`${PREFIX}/importar?certificado=${value.certificado.id}`, formData)
    }
    return axios.post(`${PREFIX}/importar`, formData)
  },

  deletarEmpresasRange(value) {
    const formData = new window.FormData()
    formData.append('arquivoEmpresas', value.arquivoEmpresas.file, value.arquivoEmpresas.fileName)
    return axios.post(`${PREFIX}/delete-range`, formData)
  },

  existeCadastroFederal(id, cadastro) {
    if (id !== undefined) {
      return axios.get(`${PREFIX}/${id}/existe?cadastro=${cadastro}`)
    }
    return axios.get(`${PREFIX}/existe?cadastro=${cadastro}`)
  },

  getEmpresasRaizCadastroFederal(id, cadastro) {
    return axios.post(`${PREFIX}/buscar-raiz?id=${id}&raiz=${cadastro}`)
  },

  baixarEmExcel(filtros) {
    return axios.post(`${PREFIX}/baixa-excel`, filtros)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },

  novaEmpresa() {
    return axios.get(`${PREFIX}/novo`)
  },

  favoritarEmpresa(id, favoritar) {
    return axios.put(`${PREFIX}/favoritar?id=${id}&favoritar=${favoritar}`)
  },

  favoritarEmpresas(ids) {
    if ((Array.isArray(ids) && ids.length > 1)) {
      return axios.put(`${PREFIX}/favoritar-empresas?ids=[${ids}]`)
    }
    return axios.put(`${PREFIX}/favoritar?id=${ids[0]}`)
  },

  atualizarConfiguracoesEcac(id, configuracoes) {
    return axios.put(`${PREFIX}/${id}/configuracoes-ecac`, configuracoes)
  },

  getConfiguracoesEcacEmpresa(id) {
    return axios.get(`${PREFIX}/${id}/configuracoes-ecac`)
  },
}
